import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(private http: HttpClient) { }

  url = "http://199.192.19.98:8080/";

  getDataLD4() {
    const uri = this.url + 'get_datald4';
    return this.http.get(uri).pipe(map(res => {
      return res;
    }))
  }

  getDataNY4() {
    const uri = this.url + 'get_datany4';
    return this.http.get(uri).pipe(map(res => {
      return res;
    }))
  }
}
