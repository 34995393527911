import { Component, OnInit } from '@angular/core';
import { ScrollTopService } from '../scrolltop.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private scrollTopService: ScrollTopService) { }

  ngOnInit() {
    this.scrollTopService.setScrollTop();
  }

}
