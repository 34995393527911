import { Component, OnInit } from '@angular/core';
import { ScrollTopService } from '../scrolltop.service';


@Component({
  selector: 'app-fx-cfd',
  templateUrl: './fx-cfd.component.html',
  styleUrls: ['./fx-cfd.component.css']
})
export class FxCfdComponent implements OnInit {

  constructor(private scrollTopService: ScrollTopService) { }

  ngOnInit() {
    this.scrollTopService.setScrollTop();
  }

}

