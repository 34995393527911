import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ClientsComponent } from './clients/clients.component';
import { StoryComponent } from './story/story.component';
import { ContactComponent } from './contact/contact.component';
import { FxCfdComponent } from './fx-cfd/fx-cfd.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { CookiepolicyComponent } from './cookiepolicy/cookiepolicy.component';
import { TermsComponent } from './terms/terms.component';


export const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomeComponent
    },
    {
        path: 'fx-cfd',
        component: FxCfdComponent
    },
    {
        path: 'clients',
        component: ClientsComponent
    },
    {
        path: 'story',
        component: StoryComponent
    },
    {
        path: 'contact',
        component: ContactComponent
    },
    {
        path: 'privacypolicy',
        component: PrivacypolicyComponent
    },
    {
        path: 'cookiepolicy',
        component: CookiepolicyComponent
    },
    {
        path: 'terms',
        component: TermsComponent
    }
]