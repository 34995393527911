import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';


import { appRoutes } from './routerConfig';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ClientsComponent } from './clients/clients.component';
import { StoryComponent } from './story/story.component';
import { ContactComponent } from './contact/contact.component';
import { FxCfdComponent } from './fx-cfd/fx-cfd.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { CookiepolicyComponent } from './cookiepolicy/cookiepolicy.component';
import { TermsComponent } from './terms/terms.component';
import { ScrollTopService } from './scrolltop.service';
import { SlickCarouselModule } from 'ngx-slick-carousel';


import { OwlModule } from 'ngx-owl-carousel';
import { MainService } from './main.service';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ClientsComponent,
    StoryComponent,
    ContactComponent,
    FxCfdComponent,
    PrivacypolicyComponent,
    CookiepolicyComponent,
    TermsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SlickCarouselModule,
    OwlModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [ScrollTopService, MainService],
  bootstrap: [AppComponent]
})
export class AppModule { }
