import { Component, OnInit } from '@angular/core';
import { ScrollTopService } from '../scrolltop.service';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import * as $ from 'jquery';
import * as AOS from 'aos'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import { MainService } from '../main.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  datald4: any;
  datany4: any;

  constructor(private scrollTopService: ScrollTopService, private mainService: MainService) {

  }

  myCarouselImages = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map((i)=> `../assets/img/clients${i}.png`);
  myCarouselOptions={items: 5, dots: true, loop:true, nav: true, autoPlay:true, autoPlayTimeout:5000, autoplayHoverPause:false};

  ngOnInit() {
    this.scrollTopService.setScrollTop();
    AOS.init()

    setInterval(() => {
      this.getDataLD4()
      this.getDataNY4()
    }, 500);

  }

  getDataLD4() {
    this.mainService.getDataLD4().subscribe(res => {  
      this.datald4 = res
    })
  }

  getDataNY4() {
    this.mainService.getDataNY4().subscribe(res => {  
      this.datany4 = res
    })
  }

}
